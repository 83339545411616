import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./routes/Login";
import Home from "./routes/Home";
import Register from "./routes/Register";
import Contatos from "./routes/Contatos";
import Assinantes from "./routes/Assinantes";
import Biblioteca from "./routes/Biblioteca";
import { Funil } from "./routes/Funil";
import AlterarSenha from "./routes/AlterarSenha";
import { NotificationProvider } from "./components/Notifications/NotificationProvider";
import EsqueciSenha from "./routes/EsqueciSenha";
import RedefinirSenha from "./routes/RedefinirSenha";
import Funis from "./routes/Funis";
import Setores from "./routes/Setores";
import Etapas from "./routes/Etapas";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Ajustes from "./routes/Ajustes";
import DashAssinante from "./routes/DashAssinante";

// const theme = createTheme({
//   palette: {
//     background: {
//       default: '#ffffff',
//     },
//     text: {
//       primary: '#5c00cd',
//     },
//     primary: {
//       main: '#5c00cd',
//     }
//   },
//   typography: {
//     allVariants: {
//       color: '#5c00cd',
//     },
//   },
//   components: {
//     MuiCardActions: {
//       styleOverrides: {
//         root: {
//           '& .MuiButton-root': {
//             variant: 'text', // Define "text" como padrão para botões dentro de CardActions
//           },
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#5c00cd',
//           color: '#ffffff',
//           '&:hover': {
//             backgroundColor: '#d1c4e9', // Cor de fundo ao passar o mouse
//           },
//         },
//       },
//     },
//     MuiInputLabel: {
//       styleOverrides: {
//         root: {
//           color: '#5c00cd',
//         },
//       },
//     },
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#5c00cd',
//           },
//           '&:hover .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#d1c4e9',
//           },
//           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#5c00cd',
//           },
//         },
//         input: {
//           color: '#5c00cd',
//         },
//       },
//     },
//   },
// });

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registrado:", registration);

        // Checa se há uma atualização disponível
        registration.onupdatefound = () => {
          const newWorker = registration.installing;
          newWorker.onstatechange = () => {
            if (newWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                console.log("Nova versão disponível! Atualizando...");
                window.location.reload(); // Força a atualização
              }
            }
          };
        };
      })
      .catch((error) => {
        console.log("Erro ao registrar o Service Worker:", error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
    <CssBaseline />
    <NotificationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ajustes" element={<Ajustes />} />
          <Route path="/alterarsenha" element={<AlterarSenha />} />
          <Route path="/assinantes" element={<Assinantes />} />
          <Route path="/biblioteca" element={<Biblioteca />} />
          <Route path="/contatos" element={<Contatos />} />
          <Route path="/dashboard" element={<DashAssinante />} />
          <Route path="/esqueci" element={<EsqueciSenha />} />
          <Route path="/funil" element={<Funil />} />
          <Route path="/funis" element={<Funis />} />
          <Route path="/etapas" element={<Etapas />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password/:token" element={<RedefinirSenha />} />
          <Route path="/register" element={<Register />} />
          <Route path="/setores" element={<Setores />} />
        </Routes>
      </BrowserRouter>
    </NotificationProvider>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
