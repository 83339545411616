import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DragDropContext } from "@hello-pangea/dnd";
import Etapa from "./Etapa";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { getFunilAssinanteByAssinante } from "../../services/srvFunil";
import { Search } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { adicionarHorasUteis } from "../../helpers/datehelper";
import toast, { Toaster } from "react-hot-toast";
import { newCard, updCard } from "../../services/SrvCard";
import ContatoForm from "../Contato/ContatoForm";
import CardNovoDlg from "./Dialogs/CardNovoDlg";
import { getContato, newContato } from "../../services/SrvContato";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EtapasContainer = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const SearchContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  padding: 8px;
  gap: 8px;
`;

const FunilDrag = React.memo(() => {
  const [etapas, setEtapas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showNovo, setShowNovo] = useState(false);

  var autenticado = false;
  var chave = null;
  var perfil = null;

  const location = useLocation();
  const { state } = location;
  const hasRun = React.useRef(false);

  if (state) {
    chave = state.token;
    perfil = state.profile;
    autenticado = state.isAuthenticated;
  }

  // console.log("perfil: ", perfil);

  async function fetchEtapas() {
    setLoading(true);
    // const funilAssinante = await getFunilAssinanteByAssinante(
    await getFunilAssinanteByAssinante(chave, perfil.assinante).then(
      (response) => {
        console.log("response: ", response);
        const funilAssinante = response;
        console.log("funilAssinante: ", funilAssinante);
        const etapasFunil = funilAssinante.etapas;
        let cardsInitial = [];
        let etapasInitial = {
          etapas: etapasFunil.reduce((acc, etapa) => {
            const etapaId = `etapa-${etapa.id}`;
            etapa.originalId = etapa.id;
            etapa.id = etapaId;
            acc[etapaId] = {
              ...etapa,
              cardIds: etapa.cards
                .filter(
                  (card) => card.status === "A" && card.usuario === perfil.id
                )
                .map((card) => {
                  card.originalId = card.id;
                  card.id = `card-${card.id}`;
                  if (card.produto) {
                    card.produto_id = card.produto.id;
                  }
                  return card.id;
                }),
            };
            cardsInitial = [
              ...cardsInitial,
              ...etapa.cards.filter(
                (card) => card.status === "A" && card.usuario === perfil.id
              ),
            ];
            delete acc[etapaId].cards;
            return acc;
          }, {}),
        };

        etapasInitial = {
          ...etapasInitial,
          cards: cardsInitial.reduce((acc, card) => {
            // const cardId = `card-${card.id}`
            acc[card.id] = card;
            return acc;
          }, {}),
        };

        etapasInitial = {
          ...etapasInitial,
          etapasOrder: etapasFunil
            .sort((a, b) => a.ordem - b.ordem)
            .map((etapa) => etapa.id),
        };

        console.log("etapas: ", etapasInitial);
        setEtapas(etapasInitial);
      },
      (erro) => {
        console.log(erro);
        toast.error(
          `Ocorreu um erro ao carregar o funil: ${erro.response.data}`
        );
      }
    );

    setLoading(false);
  }

  useEffect(() => {
    if (autenticado && !hasRun.current) {
      fetchEtapas();
      hasRun.current = true;
    }
  }, []);

  async function setCard(card, token) {
    await updCard(token, card).then(
      (response) => {
        toast.success("Cartão atualizado com sucesso!");
      },
      (erro) => {
        console.log(erro);
        if (erro.response.data.status) {
          toast.error(
            `Ocorreu um erro ao atualizar o cartão: ${erro.response.data.status}`
          );
        } else {
          toast.error(
            `Ocorreu um erro ao atualizar o cartão: ${erro.response.data}`
          );
        }
      }
    );

    if (!card.originalId) {
      card.originalId = card.id;
      card.id = `card-${card.id}`;
    }
  }

  const removeCard = (cardId, etapaId) => {
    const newState = { ...etapas };
    const etapa = newState.etapas[etapaId];
    etapa.cardIds = etapa.cardIds.filter((id) => id !== cardId);
    delete newState.cards[cardId];
    setEtapas(newState);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = etapas.etapas[source.droppableId];
    const finish = etapas.etapas[destination.droppableId];

    if (start === finish) {
      const newCardIds = Array.from(start.cardIds);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggableId);

      const newEtapa = {
        ...start,
        cardIds: newCardIds,
      };

      const newState = {
        ...etapas,
        etapas: {
          ...etapas.etapas,
          [newEtapa.id]: newEtapa,
        },
      };

      setEtapas(newState);
      return;
    }

    // Movendo de uma etapa para outra
    const startCardIds = Array.from(start.cardIds);
    startCardIds.splice(source.index, 1);
    const newStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = Array.from(finish.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    // Atualiza os elementos em tela
    const newState = {
      ...etapas,
      etapas: {
        ...etapas.etapas,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setEtapas(newState);

    // Atualiza no backend

    // setLoading(true);
    const card = etapas.cards[draggableId];
    const etapaOriginalId = finish.originalId;
    card.etapa = etapaOriginalId;
    const data_atual = new Date();
    const horas_expiracao = finish.horas_expiracao;
    const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
    card.data_lista = data_atual;
    card.data_expiracao = data_expiracao;
    card.contato_id = card.contato.id;
    setCard(card, chave).catch((error) => {
      console.error("Erro ao atualizar card:", error);
      alert("Falha ao sincronizar com o servidor. Tente novamente.");
      // TODO: Desfazer a mudança no estado
    });

    // setLoading(false);
  };

  const handleNovoClick = () => {
    setShowNovo(true);
  };

  const handleNovoClose = () => {
    setShowNovo(false);
  };

  async function addContato(dados) {
    const novoContato = await newContato(dados, chave).then(
      (response) => {
        return response;
      },
      (erro) => {
        toast.error(`Erro ao incluir contato: ${erro}`);
        return null;
      }
    );
    return novoContato;
  }

  async function addCard(novoContato, etapa, values) {
    console.log("novoContato>>> ", novoContato);
    if (novoContato !== null) {
      const data_atual = new Date();
      const horas_expiracao = etapa.horas_expiracao;
      const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
      const dadosCard = {
        contato_id: novoContato.id,
        // contato: novoContato.id,
        etapa: values.etapa,
        // produto: values.produto,
        produto_id: values.produto,
        data_lista: data_atual,
        data_expiracao: data_expiracao,
        observacoes: null,
        assinante: perfil.assinante,
        usuario: perfil.id,
      };
      await newCard(chave, dadosCard).then(
        async (response) => {
          console.log("response no newCard: ", response);
          var card = response;
          card.originalId = card.id;
          card.id = `card-${card.id}`;
          console.log("card atualizado: ", card);
          const newState = { ...etapas };
          newState.etapas[`etapa-${values.etapa}`].cardIds.push(card.id);
          newState.cards[card.id] = card;
          setEtapas(newState);
          toast.success("Cliente adicionado com sucesso ao funil!");
        },
        (error) => {
          console.log(
            "Erro ao adicionar o potencial cliente ao funil: ",
            error
          );
          toast.error(
            `Erro ao adicionar o potencial cliente ao funil: ${error.response.data}`
          );
        }
      );
    } else {
      toast.error("Erro ao adicionar o contato!");
    }
  }

  const handleNovoSave = async (values) => {
    setLoading(true);
    let novoContato = null;
    const etapa = etapas.etapas[`etapa-${values.etapa}`];
    if (values.contato === null) {
      var whatsapp = values.whatsapp.replace(/[^0-9]/g, "");
      whatsapp = parseInt(whatsapp);
      const dados = {
        nome: values.nome,
        email: values.email,
        whatsapp: whatsapp,
        telefone: values.telefone,
        cidade: values.cidade,
        assinante: perfil.assinante,
      };
      await addContato(dados).then(
        (contato) => {
          addCard(contato, etapa, values);
        },
        (erro) => {
          toast.error(`Erro ao incluir contato: ${erro}`);
        }
      );
    } else {
      novoContato = values.contato;
      const novoCard = await addCard(novoContato, etapa, values);
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {etapas && !loading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <SearchContainer>
              <Box display="flex" flexdirection="column">
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleNovoClick}
                >
                  Novo
                </Button>
                <CardNovoDlg
                  show={showNovo}
                  token={chave}
                  etapas={Object.values(etapas.etapas).filter(
                    (etapa) => etapa.permite_entrada
                  )}
                  handleSave={handleNovoSave}
                  onClose={handleNovoClose}
                />
              </Box>
              <TextField
                type="text"
                placeholder="Pesquisar"
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </SearchContainer>
            <EtapasContainer>
              {etapas.etapasOrder.map((etapaId) => {
                const etapa = etapas.etapas[etapaId];
                let cards = etapa.cardIds.map((cardId) => etapas.cards[cardId]);
                if (searchValue !== "") {
                  cards = cards.filter((card) =>
                    card.contato.nome
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  );
                }

                return (
                  <Etapa
                    key={etapa.id}
                    etapa={etapa}
                    cards={cards}
                    token={chave}
                    setCard={setCard}
                    removeCard={removeCard}
                  />
                );
              })}
            </EtapasContainer>
            <Toaster toastOptions={{ duration: 3000 }} />
          </Container>
        </DragDropContext>
      )}
    </>
  );
});

export default FunilDrag;
