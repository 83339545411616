import styled from "@emotion/styled";
import { Draggable } from "@hello-pangea/dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Fade,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { memo, useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getCard, getCardFiles, updCard } from "../../services/SrvCard";
import { getProduto } from "../../services/srvProduto";
import CardDateTimeIndicator from "../CardDateTimeIndicator";
import CheckListAbordagem from "../CheckListAbordagem";
import CurrencyEditableLabel from "../CurrencyEditableLabel";
import StarRating from "../StarRating";
import WhatsAppLink from "../WhatsAppLink";
import CardAgendarDlg from "./Dialogs/CardAgendarDlg";
import CardDeleteDlg from "./Dialogs/CardDeleteDlg";
import CardFileUploadDlg from "./Dialogs/CardFileUploadDlg";
import CardValorarDlg from "./Dialogs/CardValorarDlg";
import { motion } from "framer-motion";

const Container = styled.div`
  // padding: 8px;
  //   border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 2px;
  background-color: ${(props) => (props.isDragging ? "lightgreen" : "white")};
  display: flex;
`;

const getRandomInterval = () =>
  Math.floor(Math.random() * (15 - 5 + 1) + 5) * 60000; // Entre 2 e 10 minutos
const getInitialDelay = () =>
  Math.floor(Math.random() * (10 - 1 + 1) + 1) * 60000; // Entre 1 e 5 minutos

const Card = memo(
  ({
    card,
    etapa,
    index,
    token,
    setCard,
    removeCard,
    onStartUpdate,
    onFinishUpdate,
  }) => {
    const baseAPI = process.env.REACT_APP_BASE_API_URL;
    const [showObs, setShowObs] = useState(false);
    const [showUploadComponent, setShowUploadComponent] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [observacoes, setObservacoes] = useState(
      card.observacoes ? card.observacoes : ""
    );
    const [valorContrato, setValorContrato] = useState(card.valor_contrato);
    const [produto, setProduto] = useState(null);
    const [statusExpiracao, setStatusExpiracao] = useState(
      card.status_expiracao
    );
    // const [contato, setContato] = useState(null);
    const [updating, setUpdating] = useState(false);

    const dataExpiracao = new Date(card.data_expiracao);
    const dataToShow = dataExpiracao.toLocaleString("pt-BR", {
      timezone: "America/Sao_Paulo",
    });
    const disabled = card.status === "I";
    const temObs = card.observacoes && card.observacoes !== "";
    const dataLista = new Date(card.data_lista);
    let agendar = false;
    if (!isNaN(dataLista) && !isNaN(dataExpiracao)) {
      const dataListaISO = dataLista.toISOString();
      const dataExpiracaoISO = dataExpiracao.toISOString();
      agendar =
        dataListaISO === dataExpiracaoISO && etapa.exibe_calendario === true;
    }
    const valorar =
      etapa.nome === "Contrato Enviado" && card.valor_contrato === "0.00";
    const valorado = card.valor_contrato !== "0.00";
    const hasRun = useRef(false);

    // async function fetchProduto() {
    //   if (card.produto) {
    //     setUpdating(true);
    //     setProduto(await getProduto(token, card.produto));
    //     setUpdating(false);
    //   }
    // }

    // useEffect(() => {
    //   if (!hasRun.current) {
    //     fetchProduto();
    //     hasRun.current = true;
    //   }
    // }, []);

    useEffect(() => {
      const fetchCard = async () => {
        if (!onStartUpdate() || updating) {
          return;
        }

        try {
          setUpdating(true);

          await getCard(token, card.originalId).then(
            (response) => {
              if (card.status_expiracao !== response.status_expiracao) {
                card.status_expiracao = response.status_expiracao;
                setStatusExpiracao(response.status_expiracao);
              }
            },
            (erro) => {
              console.log("Erro ao atualizar status do card:", erro);
              toast.error(
                `Erro ao atualizar o status do card: ${erro.response.data}`
              );
            }
          );
          setUpdating(false);
        } catch (error) {
          console.log("Erro ao atualizar status do card:", error);
          toast.error(
            `Erro ao atualizar o status do card: ${error.response.data}`
          );
        } finally {
          onFinishUpdate();
        }
      };

      // Gerar um atraso inicial aleatório entre 0 e 2 minutos
      const initialDelay = getInitialDelay();

      // Esperar um tempo aleatório antes da primeira execução
      const timeout = setTimeout(() => {
        fetchCard();

        // Configurar um intervalo com tempos aleatórios entre 2 e 10 minutos
        const interval = setInterval(fetchCard, getRandomInterval());

        // Limpar intervalo ao desmontar o componente
        return () => clearInterval(interval);
      }, initialDelay);

      // Limpar timeout caso o componente seja desmontado antes da execução
      return () => clearTimeout(timeout);
    }, []);

    const handleObsClick = () => {
      setShowObs((prev) => !prev);
    };

    const handleDeleteClick = () => {
      setOpenDeleteDialog(true);
    };

    const handleUploadClick = () => {
      setShowUploadComponent(true);
    };

    const handleUploadDialogClose = async () => {
      setShowUploadComponent(false);
      setUpdating(true);
      await getCardFiles(card.originalId, token).then(
        (resp) => {
          console.log(resp);
          card.files = resp;
        },
        (erro) => {
          toast.error(`Erro ao atualizar o card: ${erro}`);
        }
      );
      setUpdating(false);
    };

    const handleCloseDeleteDialog = () => {
      setOpenDeleteDialog(false);
    };

    const handleDeleteCard = async () => {
      card.status = "I";
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      setUpdating(true);
      await updCard(token, card).then(
        (response) => {
          toast.success("Cartão inativado com sucesso!");
          setOpenDeleteDialog(false);
          if (!card.originalId) {
            card.originalId = card.id;
            card.id = `card-${card.id}`;
          }
          removeCard(card.id, etapa.id);
        },
        (erro) => {
          toast.error(
            `Ocorreu um erro ao inativar o cartão: ${erro.response.data}`
          );
          setOpenDeleteDialog(false);
        }
      );
      setUpdating(false);
    };

    const handleObsChange = (event) => {
      setObservacoes(event.target.value);
    };

    const handleObsCloseClick = () => {
      setObservacoes(card.observacoes);
      setShowObs(false);
    };

    const handleObsSaveCloseClick = async () => {
      setUpdating(true);
      card.observacoes = observacoes;
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      setCard(card, token);
      setShowObs(false);
      setUpdating(false);
    };

    const handleRatingChange = async (rating) => {
      setUpdating(true);
      card.rating = rating;
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      setCard(card, token);
      setUpdating(false);
    };

    const handleAbordagensChange = async (value) => {
      setUpdating(true);
      card.abordagens = value;
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      await setCard(card, token);
      setUpdating(false);
    };

    const updateDataReuniao = async (dateTime) => {
      setUpdating(true);
      card.data_expiracao = dateTime;
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      await setCard(card, token);
      setUpdating(false);
    };

    const handleDataReuniaoSubmit = (event) => {
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      const date = data.get("date");
      const time = data.get("time");

      // Combine date and time to create a Date object
      const dateTimeString = `${date}T${time}`;
      const dateTime = new Date(dateTimeString);

      updateDataReuniao(dateTime);
    };

    const updateValorContrato = async () => {
      setUpdating(true);
      card.valor_contrato = valorContrato;
      card.contato_id = card.contato.id;
      card.produto_id = card.produto.id;
      await setCard(card, token);
      setUpdating(false);
    };

    const handleValorFormSubmit = (event) => {
      event.preventDefault();
      updateValorContrato();
    };

    return (
      <Draggable draggableId={card.id} index={index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              <Paper
                sx={{
                  padding: "0.5rem",
                  backgroundColor: "white",
                  cursor: "move",
                  margin: 1,
                  borderRadius: 2,
                  transition: "transform 0.2s ease-in-out",
                  alignSelf: "center",
                  // border: atrasado ? '2px solid red' : (atencao ? '2px solid yellow' : '1px'),
                  // border: getBorderColor(),
                }}
                elevation={5}
              >
                <Tooltip
                  title={`whatsapp: ${card.contato?.whatsapp} - email: ${card.contato?.email}`}
                  placement="top"
                  TransitionComponent={Fade}
                  disableInteractive
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "bold" }}
                    gutterBottom
                  >
                    {card.contato?.nome}
                  </Typography>
                </Tooltip>
                <Box>
                  {agendar && (
                    <CardAgendarDlg handleSubmit={handleDataReuniaoSubmit} />
                  )}
                  {valorar && (
                    <CardValorarDlg
                      handleSubmit={handleValorFormSubmit}
                      setValor={setValorContrato}
                    />
                  )}
                </Box>
                <Typography
                  variant="caption"
                  sx={{ color: statusExpiracao === "P" ? "black" : "white" }}
                  fontSize={12}
                  gutterBottom
                >
                  {statusExpiracao === "A" && (
                    <CardDateTimeIndicator
                      type="danger"
                      text={dataToShow}
                      disabled={disabled}
                    />
                  )}
                  {statusExpiracao === "P" && (
                    <CardDateTimeIndicator
                      type="warning"
                      text={dataToShow}
                      disabled={disabled}
                    />
                  )}
                  {statusExpiracao === "E" && (
                    <CardDateTimeIndicator
                      type="normal"
                      text={dataToShow}
                      disabled={disabled}
                    />
                  )}
                </Typography>
                <Box>
                  <IconButton
                    aria-label="edit"
                    onClick={handleObsClick}
                    size="small"
                    disabled={disabled}
                  >
                    {temObs ? (
                      <Tooltip
                        title="Abrir observações"
                        placement="top"
                        TransitionComponent={Fade}
                        disableInteractive
                      >
                        <CommentIcon sx={{ fontsize: 15 }} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Adicionar observações"
                        placement="top"
                        TransitionComponent={Fade}
                        disableInteractive
                      >
                        <AddCircleIcon sx={{ fontsize: 15 }} />
                      </Tooltip>
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    edge="end"
                    onClick={handleDeleteClick}
                    disabled={disabled}
                  >
                    <Tooltip
                      title="Excluir"
                      placement="top"
                      TransitionComponent={Fade}
                      disableInteractive
                    >
                      <DeleteIcon sx={{ fontsize: 15 }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    aria-label="upload"
                    size="small"
                    edge="end"
                    onClick={handleUploadClick}
                    disabled={disabled}
                  >
                    <Tooltip
                      title="Anexar arquivo"
                      placement="top"
                      TransitionComponent={Fade}
                      disableInteractive
                    >
                      <UploadFileIcon sx={{ fontsize: 15 }} />
                    </Tooltip>
                  </IconButton>
                  <CardFileUploadDlg
                    show={showUploadComponent}
                    handleClose={handleUploadDialogClose}
                    cardId={card.originalId}
                    token={token}
                  />
                  <CardDeleteDlg
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    handleDeleteCard={handleDeleteCard}
                  />
                  {showObs && (
                    <Box sx={{ mt: 2, position: "relative" }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={observacoes}
                        onChange={handleObsChange}
                        sx={{ fontSize: 10 }}
                      />
                      <IconButton
                        aria-label="close"
                        size="small"
                        color="red"
                        onClick={handleObsCloseClick}
                      >
                        <Tooltip
                          title="Fechar sem salvar"
                          placement="bottom"
                          TransitionComponent={Fade}
                          disableInteractive
                        >
                          <CloseIcon fontSize="inherit" sx={{ color: "red" }} />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="close"
                        size="small"
                        color="blue"
                        onClick={handleObsSaveCloseClick}
                      >
                        <Tooltip
                          title="Salvar e fechar"
                          placement="bottom"
                          TransitionComponent={Fade}
                          disableInteractive
                        >
                          <CheckIcon
                            fontSize="inherit"
                            sx={{ color: "blue" }}
                          />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  )}
                  {valorado && (
                    <Box>
                      <CurrencyEditableLabel
                        initialText={valorContrato}
                        onChange={(value) => setValorContrato(value)}
                        onConfirm={updateValorContrato}
                      />
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <StarRating
                      starColor="gold"
                      stars={card.rating}
                      onRatingChange={handleRatingChange}
                      disabled={disabled}
                    />
                    <Tooltip
                      title="Chamar no WhatsApp"
                      placement="bottom"
                      TransitionComponent={Fade}
                      disableInteractive
                    >
                      <WhatsAppLink phoneNumber={card.contato?.whatsapp} />
                    </Tooltip>
                  </Box>
                  {etapa.mostra_tentativas && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="caption">Tentativas:</Typography>
                      <CheckListAbordagem
                        abordagens={card.abordagens}
                        onAbordagensChange={handleAbordagensChange}
                        disabled={disabled}
                      />
                    </Box>
                  )}
                  {card.produto && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ backgroundColor: "blue", margin: 1, padding: 1 }}
                    >
                      <Tooltip
                        title={card.produto.nome}
                        TransitionComponent={Fade}
                        disableInteractive
                      >
                        <Typography
                          fontWeight="bold"
                          fontStyle="italic"
                          color="white"
                        >
                          {card.produto.tag}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {updating && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                {card.files?.length > 0 && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="anexos-content"
                      id="anexos-header"
                    >
                      <Typography variant="caption">Anexos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {card.files.map((file, index) => (
                          <ListItem key={index}>
                            <Link
                              href={`${baseAPI}${file.file_url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ListItemText
                                primaryTypographyProps={{ variant: "caption" }}
                                primary={file.file_url.split("/").pop()}
                              />
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Paper>
            </motion.div>
            <Toaster toastOptions={{ duration: 3000 }} />
          </Container>
        )}
      </Draggable>
    );
  }
);

export default Card;
