import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CardDeleteDlg({ open, onClose, handleDeleteCard }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      disablePortal
      disableScrollLock
    >
      <DialogTitle id="delete-dialog-title">
        {"Confirma a inativação do cartão?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ao confirmar a inativação do cartão, ele será retirado do funil. Você
          poderá visualizá-lo depois nos cards inativos.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={handleDeleteCard} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
