import { AccountCircle, LocationCity } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CelularEdit from "../CelularEdit";
import EmailIcon from "@mui/icons-material/Email";
import LabelIcon from "@mui/icons-material/Label";
import FilterListIcon from "@mui/icons-material/FilterList";
import { use, useEffect, useRef, useState } from "react";
import { getProdutos } from "../../../services/srvProduto";
import toast, { Toaster } from "react-hot-toast";
import { getContatos } from "../../../services/SrvContato";

export default function CardNovoDlg({
  show,
  token,
  etapas,
  handleSave,
  onClose,
}) {
  const [formValues, setFormValues] = useState({
    nome: "",
    whatsapp: "",
    email: "",
    cidade: "",
    produto: "",
    etapa: "",
    contato: null,
  });

  const [produtos, setProdutos] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [step, setStep] = useState(1);
  const [cliente, setCliente] = useState("novo");
  const hasRun = useRef(false);

  async function fetchProdutos() {
    await getProdutos(token).then(
      (response) => {
        setProdutos(response);
      },
      (erro) => {
        console.log("Erro ao recuperar produtos!", erro);
        toast.error("Erro ao recuperar produtos!", erro);
      }
    );
  }

  async function fetchContatos() {
    await getContatos(token).then(
      (response) => {
        setContatos(response);
      },
      (erro) => {
        console.log("Erro ao recuperar contatos!", erro);
        toast.error("Erro ao recuperar contatos!", erro);
      }
    );
  }

  useEffect(() => {
    fetchProdutos();
    fetchContatos();

    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClose = () => {
    setFormValues({
      nome: "",
      whatsapp: "",
      email: "",
      cidade: "",
      produto: "",
      etapa: "",
      contato: null,
    });
    setStep(1);
    if (onClose) {
      onClose();
    }
  };

  const handleAddCard = () => {
    handleSave(formValues);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={() => {}}
      aria-labelledby="add-card-dialog"
      disablePortal
      disableScrollLock
    >
      <Button
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        X
      </Button>
      <DialogTitle>Adicionar novo cartão</DialogTitle>
      <DialogContent>
        {step === 1 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography>
              Selecione a etapa na qual o novo cartão irá iniciar no funil:
            </Typography>
            <TextField
              label="Etapa"
              variant="standard"
              id="etapa"
              name="etapa"
              select
              InputProps={{
                startAdornment: (
                  <FilterListIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
              fullWidth
              required
              onChange={handleChange}
              value={formValues.etapa}
            >
              {etapas.map((etapa) => (
                <MenuItem key={etapa.originalId} value={etapa.originalId}>
                  {etapa.nome}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {step === 2 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography>Para qual produto?</Typography>
            <TextField
              label="Produto"
              variant="standard"
              id="produto"
              name="produto"
              select
              InputProps={{
                startAdornment: (
                  <LabelIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
              fullWidth
              required
              onChange={handleChange}
              value={formValues.produto}
            >
              {produtos.map((produto) => (
                <MenuItem key={produto.id} value={produto.id}>
                  {produto.nome}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {step === 3 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography>
              Informe os dados do cliente potencial para o novo cartão:
            </Typography>
            <FormControl>
              <FormLabel id="cliente-novo-ou-existente-label">
                Utilizar um cliente
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="cliente-novo-ou-existente-label"
                name="cliente-novo-existente-group"
                onChange={(event) => {
                  setFormValues((prev) => ({
                    ...prev,
                    nome: "",
                    whatsapp: "",
                    email: "",
                    cidade: "",
                    contato: null,
                  }));
                  setCliente(event.target.value);
                }}
              >
                <FormControlLabel
                  value="novo"
                  control={<Radio checked={cliente === "novo"} />}
                  label="Novo"
                />
                <FormControlLabel
                  value="existente"
                  control={<Radio checked={cliente === "existente"} />}
                  label="Existente"
                />
              </RadioGroup>
            </FormControl>
            {cliente === "novo" && (
              <>
                <TextField
                  label="Nome"
                  id="nome"
                  name="nome"
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <AccountCircle sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                  fullWidth
                  required
                  onChange={handleChange}
                  value={formValues.nome}
                />
                <CelularEdit
                  label="WhatsApp"
                  id="whatsapp"
                  name="whatsapp"
                  variant="standard"
                  fullWidth
                  required
                  value={formValues.whatsapp}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  id="email"
                  name="email"
                  variant="standard"
                  type="email"
                  InputProps={{
                    startAdornment: (
                      <EmailIcon sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                  fullWidth
                  onChange={handleChange}
                  value={formValues.email}
                />
                <TextField
                  label="Cidade"
                  variant="standard"
                  id="cidade"
                  name="cidade"
                  InputProps={{
                    startAdornment: (
                      <LocationCity sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                  fullWidth
                  required
                  onChange={handleChange}
                  value={formValues.cidade}
                />
              </>
            )}
            {cliente === "existente" && (
              <>
                <Autocomplete
                  id="contato"
                  disablePortal
                  options={contatos}
                  getOptionLabel={(option) => option.nome}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formValues.contato}
                  onChange={(event, newValue) => {
                    setFormValues({
                      ...formValues,
                      contato: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cliente" />
                  )}
                />
              </>
            )}
          </Box>
        )}

        <Toaster toastOptions={{ duration: 3000 }} />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100vw",
            margin: 2,
          }}
        >
          {step === 1 && (
            <>
              <Button onClick={handleCancel} color="error">
                Cancelar
              </Button>
              <Button onClick={() => setStep(step + 1)}>Próximo</Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button onClick={() => setStep(step - 1)}>Voltar</Button>
              <Button onClick={() => setStep(step + 1)}>Próximo</Button>
            </>
          )}
          {step === 3 && (
            <>
              <Button onClick={() => setStep(step - 1)}>Voltar</Button>
              <Button onClick={handleAddCard} color="primary">
                Adicionar
              </Button>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
