import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { useState } from "react";
import { fileCardUpload } from "../../../services/SrvCard";

export default function CardFileUploadDlg({
  show,
  handleClose,
  cardId,
  token,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadClose = () => {
    setSelectedFile(null);
    setMessage("");
    setError(false);
    if (handleClose) handleClose();
  };

  const handleUpload = async (dados) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("card", cardId);
    const dadosJson = Object.fromEntries(formData.entries());

    setUploading(true);
    setMessage("");
    setError(false);

    try {
      const response = await fileCardUpload(dadosJson, token);
      setMessage("Upload realizado com sucesso!");
    } catch (err) {
      console.log(err);
      setMessage("Erro ao realizar o upload.");
      setError(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={handleUploadClose}
      maxWidth="sm"
      fullWidth
      disablePortal
      disableScrollLock
    >
      <DialogTitle>Anexar Arquivo</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            sx={{ mb: 2 }}
          >
            Selecionar Arquivo
            <input type="file" hidden onChange={handleFileChange} />
          </Button>

          {selectedFile && (
            <Typography variant="body1">
              {`Arquivo selecionado: ${selectedFile.name}`}
            </Typography>
          )}
        </Box>

        {message && (
          <Typography
            variant="body1"
            color={error ? "error" : "success"}
            sx={{ mt: 2, textAlign: "center" }}
          >
            {message}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={2}>
          <Button onClick={handleUploadClose} color="secondary">
            Fechar
          </Button>

          <Button
            onClick={handleUpload}
            color="primary"
            variant="contained"
            disabled={!selectedFile || uploading}
            startIcon={uploading && <CircularProgress size={20} />}
          >
            {uploading ? "Enviando..." : "Enviar"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
