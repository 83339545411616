import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box, Toolbar } from "@mui/material";
import HomeBar from "../components/HomeBar/HomeBar";
import { useLocation } from "react-router-dom";
import { adicionarHorasUteis } from "../helpers/datehelper";
import Lista from "../components/Funil/Lista";
import { getContatos } from "../services/SrvContato";
import { getListas, initListas } from "../services/SrvLista";
import { getCards, newCard, updCard } from "../services/SrvCard";
import ContatosGrid from "../components/ContatosGrid";
import ProdutoLista from "../components/Produto/ProdutoLista";
import toast, { Toaster } from "react-hot-toast";
import SideOption from "../components/Funil/SideOption";
import FunilDrag from "../components/Funil/Funil";

const ItemTypes = {
  CARD: "card",
};

const Funil = () => {
  const [contatoSelecionado, setContatoSelecionado] = useState(null);
  const [contatos, setContatos] = useState(null);
  const [addContatoBtnDisabled, setAddContatoBtnDisabled] = useState(true);
  const [menuLateral, setMenuLateral] = useState({
    funil: true,
    clientes: false,
    cardsInativos: false,
    produtos: false,
  });
  const [listas, setListas] = useState([]);

  var autenticado = false;
  var chave = null;
  var perfil = null;
  var nome = null;

  const location = useLocation();
  const { state } = location;

  if (state) {
    chave = state.token;
    perfil = state.profile;
    autenticado = state.isAuthenticated;
    nome = state.nome;
  }

  const handleMenuLateralClick = (opcao) => {
    switch (opcao) {
      case "funil":
        setMenuLateral({
          funil: true,
          clientes: false,
          cardsInativos: false,
          produtos: false,
        });
        break;
      case "clientes":
        setMenuLateral({
          funil: false,
          clientes: true,
          cardsInativos: false,
          produtos: false,
        });
        break;
      case "cardsInativos":
        setMenuLateral({
          funil: false,
          clientes: false,
          cardsInativos: true,
          produtos: false,
        });
        break;
      case "produtos":
        setMenuLateral({
          funil: false,
          clientes: false,
          cardsInativos: false,
          produtos: true,
        });
        break;
      default:
        setMenuLateral({
          funil: true,
          clientes: false,
          cardsInativos: false,
          produtos: false,
        });
        break;
    }
  };

  async function fetchListas() {
    await getListas(chave).then(
      (response) => {
        // console.log('No fetchListas: ', response)
        if (response.length === 0) {
          // não há listas criadas ainda para o assinante
          initializeListas();
        } else {
          setListas(response);
        }
      },
      (error) => {
        // console.log('Erro ao recuperar listas: ', error)
        toast.error(`Erro ao recuperar listas: ${error}`);
      }
    );
  }

  async function fetchContatos() {
    await getContatos(chave).then(
      (response) => {
        // console.log('response no fetchContatos: ', response)
        setContatos(response);
      },
      (error) => {
        // console.log('Erro ao recuperar contatos: ', error)
        toast.error(`Erro ao recuperar contatos: ${error}`);
      }
    );
  }

  async function initializeListas() {
    await initListas(chave).then(
      (response) => {
        setListas(response);
        toast.success("Funil inicializado com sucesso!");
      },
      (error) => {
        // console.log('Erro ao inicializar funil', error)
        toast.error(`Erro ao inicializar funil: ${error}`);
      }
    );
  }

  // useEffect(() => {
  //     fetchContatos()
  //     fetchListas()
  //     // eslint-disable-next-line
  // }, [])

  async function fetchCards() {
    return await getCards(chave);
  }

  const handleAddContatoClick = () => {
    if (contatoSelecionado) {
      fetchCards().then(
        (response) => {
          const cards = response;
          if (cards.length > 0) {
            const contatoCards = cards.filter(
              (card) => card.contato === contatoSelecionado.id
            );
            if (contatoCards.length > 0) {
              toast.error("O cliente potencial já está no funil");
            } else {
              addCard(contatoSelecionado);
            }
            // console.log('cards: ', cards)
          } else {
            addCard(contatoSelecionado);
          }
        },
        (error) => {
          // console.log('erro ao recuperar cards: ', error)
          toast.error(`Erro ao recuperar cards: ${error}`);
        }
      );
    }
  };

  const handleContatoSelecionado = (item) => {
    setContatoSelecionado(item);
    setAddContatoBtnDisabled(false);
  };

  async function setCard(card) {
    const cardAtualizado = await updCard(chave, card).then(
      (response) => {
        return response;
      },
      (erro) => {
        // console.log('Ocorreu um erro ao atualizar o card: ', erro)
        toast.error(`Ocorreu um erro ao atualizar o card: ${erro}`);
        return null;
      }
    );

    return cardAtualizado;
  }

  async function addCard(contatoSelecionado, lista, produtoId) {
    const data_atual = new Date();
    const horas_expiracao = lista.horas_expiracao;
    const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
    const dados = {
      contato: contatoSelecionado.id,
      lista: lista.id,
      produto: produtoId,
      data_lista: new Date(),
      data_expiracao: data_expiracao,
      observacoes: null,
    };
    await newCard(chave, dados).then(
      (response) => {
        fetchListas();
        toast.success("Cliente adicionado com sucesso ao funil!");
      },
      (error) => {
        toast.error(`Erro ao adicionar o potencial cliente ao funil: ${error}`);
      }
    );
  }

  const moveCard = (sourceIndex, sourceListaId, destListaId) => {
    const sourceLista = listas.find((lista) => lista.id === sourceListaId);
    // console.log('sourceLista: ', sourceLista)
    const destLista = listas.find((lista) => lista.id === destListaId);
    // console.log('destLista: ', destLista)

    if (sourceLista && destLista) {
      const [card] = sourceLista.cards.splice(sourceIndex, 1);

      if (card) {
        const data_atual = new Date();
        const horas_expiracao = destLista.horas_expiracao;
        const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);

        card.lista = destListaId;
        card.data_lista = data_atual;
        card.data_expiracao = data_expiracao;
        setCard(card).then((response) => {
          fetchListas();
        });
      }
    }

    // destLista.cards.push(card);
    // setListas([...listas]);
  };

  return (
    <>
      <HomeBar
        nome={perfil.nome}
        token={chave}
        profile={perfil}
        isAuthenticated={autenticado}
      />
      <Toolbar />
      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        sx={{ height: "100vh", marginTop: 1 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxWidth: 60,
            backgroundColor: "whitesmoke",
            padding: 2,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <SideOption
            option="funil"
            title="Funil"
            menuLateral={menuLateral}
            handleMenuLateralClick={handleMenuLateralClick}
          />
          <SideOption
            option="clientes"
            title="Clientes"
            menuLateral={menuLateral}
            handleMenuLateralClick={handleMenuLateralClick}
          />
          <SideOption
            option="cardsInativos"
            title="Cards Inativos"
            menuLateral={menuLateral}
            handleMenuLateralClick={handleMenuLateralClick}
          />
          <SideOption
            option="produtos"
            title="Produtos"
            menuLateral={menuLateral}
            handleMenuLateralClick={handleMenuLateralClick}
          />
        </Box>
        {menuLateral.funil && <FunilDrag />}
        {menuLateral.clientes && (
          <Box
            flexGrow={1}
            p={2}
            sx={{ backgroundColor: "#f0e6fb", height: "100vh" }}
          >
            <ContatosGrid
              nome={nome}
              token={chave}
              profile={perfil}
              isAuthenticated={autenticado}
            />
          </Box>
        )}
        {menuLateral.cardsInativos && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              justifyContent: "space-around",
              height: "100vh",
            }}
          >
            <DndProvider backend={HTML5Backend}>
              {listas
                .sort((a, b) => a.ordem - b.ordem)
                .map((lista) => (
                  <Lista
                    key={lista.id}
                    lista={lista}
                    moveCard={moveCard}
                    token={chave}
                    perfil={perfil}
                    fetchListas={fetchListas}
                    statusCards={"I"}
                    addCard={addCard}
                  />
                ))}
            </DndProvider>
          </Box>
        )}
        {menuLateral.produtos && <ProdutoLista perfil={perfil} chave={chave} />}
        ;
      </Box>

      <Toaster toastOptions={{ duration: 5000 }} />
    </>
  );
};

export { Funil, ItemTypes };
