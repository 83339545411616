import styled from "@emotion/styled";
import Card from "./Card";
import { Droppable } from "@hello-pangea/dnd";
import { useState } from "react";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-width: 280px;
  max-width: 320px;

  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  padding: 2px;
  align-self: center;
`;
const CardList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? "skyblue" : "white")};
  flex-grow: 1;
  min-height: 150px;
`;
const MAX_CONCURRENT_UPDATES = 5; // Defina o número máximo de atualizações simultâneas

export default function Etapa({ etapa, cards, token, setCard, removeCard }) {
  const [activeUpdates, setActiveUpdates] = useState(0);

  const canStartUpdate = () => {
    if (activeUpdates < MAX_CONCURRENT_UPDATES) {
      setActiveUpdates((prev) => prev + 1);
      return true;
    }
    return false;
  };

  const finishUpdate = () => {
    setActiveUpdates((prev) => Math.max(prev - 1, 0));
  };

  return (
    <Container>
      <Title>{etapa.nome}</Title>
      <Droppable droppableId={etapa.id}>
        {(provided, snapshot) => (
          <CardList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {cards.map((card, index) => (
              <Card
                key={card.id}
                card={card}
                etapa={etapa}
                index={index}
                token={token}
                setCard={setCard}
                removeCard={removeCard}
                onStartUpdate={canStartUpdate}
                onFinishUpdate={finishUpdate}
              />
            ))}
            {provided.placeholder}
          </CardList>
        )}
      </Droppable>
    </Container>
  );
}
